import React, { useState, useEffect } from 'react';
import logo from './img/fklogo.jpg';
import './App.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import API from './API';
import Form from 'react-bootstrap/Form';
import { PieChart, Pie, Cell } from 'recharts';
import { Container, Row, Col } from 'react-bootstrap';
interface MachineEvent {
  machine_id: number,
  datapoint_id: number,
  timestamp: number
}

interface Machine {
  id: number,
  machine_name: string,
  nameplate_cycle: number,
  created_at: string,
  updated_at: string,
}

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

interface ChartData {
  name: string;
  value: number;
}

function App() {

  const [machines, setMachines] = useState<Array<Machine>>([])
  const [selectedMachine, setSelectedMachine] = useState<Machine | undefined>(undefined)


  const [pFactor, setPFactor] = useState(0);
  const [qFactor, setQFactor] = useState(0);
  const [aFactor, setAFactor] = useState(0);
  const [oee, setOee] = useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let mId = Number(event.target.value)
    let m = machines.find(m => m.id === mId)
    if (m) {
      setSelectedMachine(m)
      resetOEE()
    }
  };

  const [dataOEE, setDataOEE] = useState<ChartData[]>([{ name: 'result', value: 0 }, { name: 'lost', value: 1 }])
  const [dataPfacor, setDataPfacor] = useState<ChartData[]>([{ name: 'result', value: 0 }, { name: 'lost', value: 1 }])
  const [dataAfacor, setDataAfacor] = useState<ChartData[]>([{ name: 'result', value: 0 }, { name: 'lost', value: 1 }])
  const [dataQfacor, setDataQfacor] = useState<ChartData[]>([{ name: 'result', value: 0 }, { name: 'lost', value: 1 }])


  const COLORS_PFACTOR = ['#454545', 'LIGHTGRAY'];
  const COLORS_AFACTOR = ['BLUE', 'LIGHTGRAY'];
  const COLORS_QFACTOR = ['RED', 'LIGHTGRAY'];
  const COLORS_OEEFACTOR = ['GREEN', 'LIGHTGRAY'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: CustomizedLabelProps) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const getCurrentOEE = () => {
    if (!simStarted) {
      setSimTimer((t) => t + 1)
    }
    const url =
      `oee/${Number(selectedMachine?.id)}`;
    API.get(url).then((res) => {
      const oeeData = res.data[0];
      setPFactor(oeeData.pfactor)
      setQFactor(oeeData.qfactor)
      setAFactor(oeeData.afactor)

      setGood(oeeData.gcount)
      setBad(oeeData.bcount)
      setOee(oeeData.OEE)
      setDataAfacor([{ name: 'result', value: oeeData.afactor }, { name: 'lost', value: 1 - oeeData.afactor }])
      setDataPfacor([{ name: 'result', value: oeeData.pfactor }, { name: 'lost', value: 1 - oeeData.pfactor }])
      setDataQfacor([{ name: 'result', value: oeeData.qfactor }, { name: 'lost', value: 1 - oeeData.qfactor }])
      setDataOEE([{ name: 'result', value: oeeData.OEE }, { name: 'lost', value: 1 - oeeData.OEE }])
    });
  };

  const resetOEE = () => {
    setSimTimer(0)
    const url =
      'reset_oee';
    API.get(url).then((res) => {
      setPFactor(0)
      setQFactor(0)
      setAFactor(0)
      setGood(0)
      setBad(0)
      setOee(0)
    });
  };


  const productionOF = () => {
    window.clearInterval(myIntNum2)
    setMyIntNum2(0)
  }

  const productionON = () => {
    myInterval2 = window.setInterval(() => {
      let rNum = Math.floor(Math.random() * 11)
      let res = rNum > 7 ? 6 : 5;
      postNewEvent({ machine_id: Number(selectedMachine?.id), datapoint_id: res, timestamp: Date.now() } as MachineEvent)
    }, Number(selectedMachine?.nameplate_cycle) + 500);
    setMyIntNum2(myInterval2)
  }

  const getMachines = () => {
    const url =
      'get_machines';
    API.get(url).then((res) => {
      let machs = res.data as Machine[]
      machs.unshift({ id: 0, machine_name: 'select', nameplate_cycle: 0, created_at: '', updated_at: '' })
      setMachines(res.data)
    });
  }


  const postNewEvent = (nEvent: MachineEvent) => {
    const url =
      'new_event';
    API.post(url, nEvent).then((res) => {
      console.log("OK")
    });
  }
  let myInterval: number;
  let myInterval2: number;

  const [myIntNum, setMyIntNum] = useState(0)
  const [simTimer, setSimTimer] = useState(0)

  const [myIntNum2, setMyIntNum2] = useState(0)

  const startSimulation = () => {
    setSimStarted(true)
    postNewEvent({ machine_id: Number(selectedMachine?.id), datapoint_id: 1, timestamp: Date.now() })
    myInterval = window.setInterval(getCurrentOEE, 1000);
    setMyIntNum(myInterval)
  }

  const stopSimulation = () => {
    setSimStarted(false)
    postNewEvent({ machine_id: Number(selectedMachine?.id), datapoint_id: 2, timestamp: Date.now() })
    if (myIntNum) {
      window.clearInterval(myIntNum)
      setMyIntNum(0)
      setTimeout(() => getCurrentOEE(), 2000)
    }
  }

  const startRunningMode = () => {
    postNewEvent({ machine_id: Number(selectedMachine?.id), datapoint_id: 3, timestamp: Date.now() })
    setRunnig(true)
    setTimeout(productionON, 2000)
  }

  const stopRunningMode = () => {
    postNewEvent({ machine_id: Number(selectedMachine?.id), datapoint_id: 4, timestamp: Date.now() })
    setRunnig(false)
    productionOF()
  }


  const [good, setGood] = useState(0);
  const [bad, setBad] = useState(0);

  const [simStarted, setSimStarted] = useState(false);
  const [running, setRunnig] = useState(false);

  useEffect(() => {
    getMachines()
    return () => {
    };
  }, []);


  return (
    <div className="App">

      <br />
      <Row style={{ marginBottom: "8px", margin: "auto", justifyContent: "center" }}>
        <Col style={{ maxWidth: "300px" }}>
          <h2>OEE SIMULATOR</h2>  <p>Coded by <b>Wojciech Brożonowicz</b> for:</p>
          
        </Col>
        <Col style={{ maxWidth: "300px" }}>
          <img src={logo} alt="logo" />
        </Col>
        <Col style={{ maxWidth: "300px", backgroundColor: "lightgray", borderRadius: "8px", padding: "4px", border: '2px solid gray' }}>
          <Form.Label>Machine:
            {Number(selectedMachine?.id) > 0 ?
              ` optimal cycle =${Number(selectedMachine?.nameplate_cycle) / 1000}s`
              : null}

          </Form.Label>
          <Form.Select onChange={handleChange} disabled={simStarted} aria-label="Default select example" style={{ maxWidth: "280px", margin: "auto", marginBottom: "8px", textAlign: "center" }}>
            {machines.map((m) => (
              <option key={m.id} value={m.id}>{m.machine_name}</option>
            )
            )
            }
          </Form.Select>

        </Col>
      </Row>


      <Container>
        <Row style={{ margin: "auto", justifyContent: "center" }}>
          <Col style={{ maxWidth: "200px" }}>

            <PieChart width={200} height={200}>
              <Pie
                data={dataPfacor}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={undefined}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {dataOEE.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS_PFACTOR[index % COLORS_PFACTOR.length]} />
                ))}
              </Pie>
            </PieChart>
            <Button variant="outline-secondary" style={{ minWidth: "150px" }}>Productivity {(Number(pFactor) * 100).toFixed(1)}%</Button>
          </Col>
          <Col style={{ maxWidth: "200px" }}>

            <PieChart width={200} height={200}>
              <Pie
                data={dataAfacor}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={undefined}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {dataOEE.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS_AFACTOR[index % COLORS_AFACTOR.length]} />
                ))}
              </Pie>
            </PieChart>
            <Button variant="outline-primary" style={{ minWidth: "150px" }}>Availability {(Number(aFactor) * 100).toFixed(1)}%</Button>
          </Col>
          <Col style={{ maxWidth: "200px" }}>
            <PieChart width={200} height={200}>
              <Pie
                data={dataQfacor}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={undefined}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"
              >
                {dataOEE.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS_QFACTOR[index % COLORS_QFACTOR.length]} />
                ))}
              </Pie>
            </PieChart>
            <Button variant="outline-danger" style={{ minWidth: "150px" }}>Quality {(Number(qFactor) * 100).toFixed(1)}%</Button>
          </Col>
          <Col style={{ maxWidth: "200px", marginLeft: "50px" }}>
            <PieChart width={200} height={200}>
              <Pie
                data={dataOEE}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={undefined}
                outerRadius={80}
                fill="#8884d8"
                dataKey="value"

              >
                {dataOEE.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS_OEEFACTOR[index % COLORS_OEEFACTOR.length]} />
                ))}
              </Pie>
            </PieChart>
            <Button variant="outline-success" style={{ marginLeft: "10px",minWidth: "150px" }}>OEE {(Number(oee) * 100).toFixed(1)}%</Button>
          </Col>
        </Row>

      </Container>

      <br />
      <Row style={{ marginBottom: "12px", margin: "auto", justifyContent: "center" }}>
        <Col style={{ maxWidth: "400px", backgroundColor: "lightgray", borderRadius: "8px", border: '2px solid gray', padding: "6px", marginRight: "4px" }}>
          <p>SIMULATION CONTROL PANEL:{'   '}{!simStarted ? <Button disabled={simStarted} variant="warning" onClick={() => resetOEE()}>RESET</Button> : null}</p>
          <p>
            <Button onClick={() => startSimulation()} style={{ marginRight: "4px" }} disabled={simStarted || !selectedMachine || Number(selectedMachine?.id) === 0}>start </Button>
            <Button style={{ marginRight: "4px", minWidth: "200px" }} variant={!simStarted ? "secondary" : "success"}>SIMULATION </Button>
            <Button onClick={() => stopSimulation()} style={{ marginRight: "4px" }} disabled={!simStarted || running}>stop </Button>
          </p>

          <p>
            <Button onClick={() => startRunningMode()} style={{ marginRight: "4px" }} disabled={running || !simStarted}>start </Button>
            <Button style={{ marginRight: "4px", minWidth: "200px" }} variant={!running ? "secondary" : "success"}>RUNNING MODE</Button>
            <Button onClick={() => stopRunningMode()} style={{ marginRight: "4px" }} disabled={!running}>stop </Button>
          </p>


        </Col>

        <Col style={{ maxWidth: "400px", backgroundColor: "white", borderRadius: "8px", border: '2px solid gray', padding: "6px", marginRight: "4px" }}>
          <p>SIMULATION RESULTS:{'   '}</p>

          <p>Total time=<b>{simTimer}</b>s  </p>
          <p>
            Pieces: {' '}
            <Button style={{ marginRight: "4px", minWidth: "80px" }} variant="outline-success">good {good}</Button>
            <Button style={{ marginRight: "4px", minWidth: "80px" }} variant="outline-danger">bad {bad}</Button>
            <Button style={{ marginRight: "4px", minWidth: "80px" }} variant="outline-primary">sum <b> {Number(good) + Number(bad)} </b></Button>
          </p>

        </Col>
      </Row>
      <br />
    </div>
  );
}

export default App;
